import {
  Divider,
  Typography,
  Breadcrumb,
  BreadcrumbText
} from '@org-crowley/enterprise-react-component-library';
import cn from 'classnames';
import { Dispatch, ReactNode } from 'react';
import {
  ArrivalDepartureStatus,
  VoyageLegContainer
} from '../../models/Voyage';
import {
  VoyageAction,
  VoyageActionType,
  VoyageState
} from '../../reducers/voyageOverviewReducer';
import VoyageLegIcon from './VoyageLegIcon';

export interface VoyageLegExplorerItemProps {
  children: ReactNode;
  status: ArrivalDepartureStatus;
  selected: boolean;
  selectVoyageLeg: () => void;
}

const Li = ({
  children,
  status,
  selected,
  selectVoyageLeg
}: VoyageLegExplorerItemProps) => (
  <li
    className={cn(
      'relative',
      'm-0',
      'py-4',
      'pl-11',
      'flex',
      'items-center',
      'hover:cursor-pointer',
      {
        'before:bg-silver-50 before:content-[""] before:absolute before:w-[1px] before:top-0 before:left-8 before:bottom-0 first:before:top-10 last:before:h-10':
          selected === false,
        'border border-silver-30 rounded-md !py-4': selected === true
      }
    )}
    onClick={selectVoyageLeg}
  >
    <VoyageLegIcon
      status={status}
      size={16}
      className="ml-[-20px] mr-11 bg-white z-10"
    />
    {children}
  </li>
);

export const determineVoyageLegStatus = (
  voyageLegContainer: VoyageLegContainer | undefined
) => {
  const firstVoyageLeg = voyageLegContainer?.voyageLegs[0];
  const lastVoyageLeg =
    voyageLegContainer?.voyageLegs[voyageLegContainer.voyageLegs.length - 1];

  const status =
    lastVoyageLeg?.ActualBerthDepartureLocal ||
    lastVoyageLeg?.ActualPortDepartureLocal
      ? ArrivalDepartureStatus.TE
      : firstVoyageLeg?.ActualBerthArrivalLocal ||
        firstVoyageLeg?.ActualPortArrivalLocal
      ? ArrivalDepartureStatus.AR
      : ArrivalDepartureStatus.Estimated;
  return status;
};

export interface VoyageLegExplorerProps {
  voyageState: VoyageState;
  voyageDispatch: Dispatch<VoyageAction>;
}

export function VoyageLegExplorer({
  voyageState,
  voyageDispatch
}: VoyageLegExplorerProps) {
  const {
    currentVoyageLegContainers,
    selectedVoyageLegContainer,
    selectedVoyage
  } = voyageState;

  const handleSelectVoyageLegContainer = (
    voyageLegContainer: VoyageLegContainer
  ) =>
    voyageDispatch({
      type: VoyageActionType.SET_VOYAGE_LEG,
      payload: { selectedVoyageLegContainer: voyageLegContainer }
    });

  const onBackClick = () =>
    voyageDispatch({
      type: VoyageActionType.BACK_BUTTON,
      payload: {
        selectedVoyage: undefined,
        selectedVoyageLegContainer: undefined
      }
    });
  return (
    <>
      <div className="flex justify-between">
        <Typography variant="h400" as="h6" className="py-4 px-2">
          Voyage Schedule
        </Typography>
        {selectedVoyage ? (
          <Breadcrumb
            className="px-1 bg-transparent"
            onBackClick={() => onBackClick()}
          >
            <BreadcrumbText>Voyages</BreadcrumbText>
          </Breadcrumb>
        ) : null}
      </div>
      <Divider className="mt-1 mb-0" />
      <ul
        className={cn(
          'lg:overflow-y-auto',
          `lg:max-h-[calc(100vh_-_450px_-_5.25rem)]`
        )}
      >
        {currentVoyageLegContainers?.map(
          (voyageLegContainer: VoyageLegContainer, index: number) => {
            const firstVoyageLeg = voyageLegContainer.voyageLegs[0];

            return (
              <Li
                key={index}
                status={determineVoyageLegStatus(voyageLegContainer)}
                selected={
                  firstVoyageLeg.SK ===
                  selectedVoyageLegContainer?.voyageLegs[0]?.SK
                }
                selectVoyageLeg={() =>
                  handleSelectVoyageLegContainer(voyageLegContainer)
                }
              >
                <div className="flex flex-col">
                  <Typography variant="body-small" className="text-silver-100">
                    {voyageLegContainer.Port}
                  </Typography>
                </div>
              </Li>
            );
          }
        )}
      </ul>
    </>
  );
}
