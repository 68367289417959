import { NOT_AVAILABLE_TEXT } from '../../utils/textCopy';
import { formatDateTime, convertToUTC } from '../../utils/formatDateTime';
import { StackedText } from '../StackedText/StackedText';
import { Tooltip } from '@org-crowley/enterprise-react-component-library/build/components/Tooltip/Tooltip';
import { MdInfoOutline } from 'react-icons/md';
import { Typography } from '@org-crowley/enterprise-react-component-library';

interface DateDisplayProps {
  date?: number;
  /**
   * Renders text that says "Not available"
   * if the provided date is undefined
   * or null. Defaults to true.
   */
  renderNotAvailableIfBlank?: boolean;
}

export function DateDisplay({
  date,
  renderNotAvailableIfBlank = true
}: DateDisplayProps) {
  let content = <></>;

  if (date) {
    const { formattedDate, formattedTime } = formatDateTime(date);
    const { formattedUTCDate, formattedUTCTime, formattedUTCZone } =
      convertToUTC(date);
    content = (
      <div className="flex flex-row items-end">
        <StackedText title={formattedTime} subTitle={formattedDate} />
        <Tooltip
          tooltipText={
            formattedUTCTime +
            ' ' +
            formattedUTCDate +
            ' (' +
            formattedUTCZone +
            ')'
          }
          placement="bottom"
        >
          <MdInfoOutline
            size={16}
            className="text-silver-40 mb-1 ml-2 -mt-[1.3rem]"
          />
        </Tooltip>
      </div>
    );
  } else if (renderNotAvailableIfBlank) {
    content = (
      <div className="flex flex-row items-end">
        <Typography variant="body-small" className="text-silver-90">
          {NOT_AVAILABLE_TEXT}
        </Typography>
      </div>
    );
  }

  return content;
}
