import cn from 'classnames';
import { Typography } from '@org-crowley/enterprise-react-component-library';
import { NOT_AVAILABLE_TEXT } from '../../utils/textCopy';

interface StackedTextProps {
  title?: string;
  subTitle?: string;
  /**
   * Renders text that says "Not available"
   * if the provided date is undefined
   * or null. Defaults to true.
   */
  renderNotAvailableIfBlank?: boolean;
  titleClassName?: string;
  subTitleClassName?: string;
}

export function StackedText({
  title,
  subTitle,
  renderNotAvailableIfBlank = true,
  titleClassName,
  subTitleClassName
}: StackedTextProps) {
  const notAvailableText = renderNotAvailableIfBlank ? NOT_AVAILABLE_TEXT : '';
  return (
    <div className="flex flex-col">
      <Typography
        variant="body-small"
        className={cn(titleClassName ?? 'text-silver-100')}
      >
        {title ?? notAvailableText}
      </Typography>
      <Typography
        variant="body-small"
        className={cn(subTitleClassName ?? 'text-silver-90')}
      >
        {subTitle ?? notAvailableText}
      </Typography>
    </div>
  );
}
