import { ChangeEvent, useMemo, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { Input } from '../Input/Input';
import { debounce } from 'lodash';
import { TextFilterBy, TextFilters } from '../../utils/useFilterState';
export interface Props {
  filters: TextFilters;
  filterType: TextFilterBy;
  searchTextValue: (filterType: TextFilterBy, textInput: string) => void;
  placeHolder: string;
}

export function FilterTextInput({
  filters,
  filterType,
  searchTextValue,
  placeHolder
}: Props) {
  const [textInput, setTextInput] = useState<string>(filters[filterType] ?? '');

  const handleSearch = useMemo(
    () =>
      debounce((filterType, value) => {
        searchTextValue(filterType, value);
      }, 500),
    [searchTextValue]
  );

  const onTextValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTextInput(value);
    handleSearch(filterType, value);
  };

  return (
    <Input
      value={textInput}
      onChange={onTextValueChange}
      placeholder={placeHolder}
      icon={<MdSearch size={20} className="absolute top-5 right-3" />}
      className="w-full sm:w-auto my-2"
    />
  );
}
