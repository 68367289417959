import { Header } from '@org-crowley/enterprise-react-component-library';
import { useContext } from 'react';
import { FilterContext } from '../../contexts/FilterContext';
import { FilterTextInput } from '../FilterTextInput/FilterTextInput';
import TopNavigation from '../TopNavigation/TopNavigation';

export function ActionBar() {
  const { activeTextFilters, onTextFilterChangeValue } =
    useContext(FilterContext);

  return (
    <Header title="Vessel Tracker" level={5} className="px-9 sm:px-8">
      <div className="flex flex-grow justify-between">
        <FilterTextInput
          filters={activeTextFilters}
          filterType="VesselName"
          searchTextValue={onTextFilterChangeValue}
          placeHolder="Vessel name"
        />
        <div className="sm:pb-0 flex items-center">
          <TopNavigation />
        </div>
      </div>
    </Header>
  );
}
