import { Typography } from '@org-crowley/enterprise-react-component-library';
import {
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState
} from 'react';
import cn from 'classnames';
interface Props {
  limits: { min: number; max: number };
  labels?: {
    min: string | number | React.ReactNode;
    max: string | number | React.ReactNode;
  };
  tickmarks?: (number | undefined)[];
  tickmarksIcon?: React.ReactNode;
  tickmarksIconSize?: number;
  value: number;
  id: string;

  onChange: ChangeEventHandler<HTMLInputElement>;
  onMouseUp?: MouseEventHandler<HTMLInputElement> | undefined;
  disabled?: boolean;
  className?: string;
}

const TICKMARKS_LEFT_VALUE_PX = 2;
/* TICKMARKS: Array of index positions, within the range 0 to (limits.max - limits.min),
 which will be the lenght of the slider,
 if no tickmarksIcon is passed as prop, every tickmark value will appear as a dot inside the slider.
 If an tickmarksIcon or any other node is passed to the slider, it will show above the slider.
 The tickmarksIcon or node width has to be provided as the tickmarksIconSize prop to the component,
 so those are properly aligned with the tickmark values */
export function RangeSlider({
  limits,
  labels,
  value,
  tickmarks,
  tickmarksIcon,
  tickmarksIconSize,
  onChange,
  onMouseUp,
  id,
  disabled = false,
  className
}: Props) {
  let arrayPosition = 0;
  /*   Here we calculate the positions % for the tickmarks, remember,
 the slider will use indexes from 0 to (limit.max-limits.min),
  so you'll have to calculate this positions in order to pass them to the tickmars[] prop
 */
  const positions = useMemo(() => {
    const calculateTickmarksPosition = (value: number) =>
      limits.max - limits.min !== 0
        ? (value * 100) / (limits.max - limits.min)
        : 0;
    return tickmarks?.map(
      (value) =>
        typeof value !== 'undefined' && `${calculateTickmarksPosition(value)}%`
    );
  }, [limits.max, limits.min, tickmarks]);

  /* Since the slider has rounded borders, its full widht is not usable for the values to be positioned,
    so the datalist lenght will be sliglthly less than the full slider container.
    We need the exact length for the tickmarks to match.
  */
  const [datalistWidth, setDatalistWidth] = useState(
    document.getElementById('tickmarks')?.offsetWidth
  );

  useEffect(() => {
    !datalistWidth &&
      setDatalistWidth(document.getElementById('tickmarks')?.offsetWidth);
  }, [datalistWidth]);

  return (
    <div
      className={cn(
        'flex justify-center align-center whitespace-nowrap',
        className
      )}
    >
      {labels && (
        <Typography variant="body-small" className="mr-1 whitespace-normal">
          {labels.min}
        </Typography>
      )}
      <div className="relative w-full h-fit">
        {tickmarks && (
          <div
            className="px-2 relative h-full m-auto"
            style={{ width: `${datalistWidth}px` }}
          >
            <datalist
              id="tickmarks"
              className="flex pb-[1px] justify-end w-full pointer-events-none select-none"
            >
              {tickmarks?.map((value, key) => {
                return (
                  <div
                    key={key}
                    className="absolute h-1 focus:cursor-pointer text-red-50"
                    style={{
                      left: `calc(${positions?.[arrayPosition++]} - ${
                        tickmarksIconSize
                          ? tickmarksIconSize / 2
                          : TICKMARKS_LEFT_VALUE_PX
                      }px)`,
                      top: `${tickmarksIconSize ? '-5px' : 'unset'}`
                    }}
                  >
                    {tickmarksIconSize ? tickmarksIcon : ''}
                    <option
                      value={value}
                      className="absolute h-1 p-0 text-red-50"
                    >
                      {tickmarksIcon ? '' : '•'}
                    </option>
                  </div>
                );
              })}
            </datalist>
          </div>
        )}
        <input
          id={id}
          data-testid={id}
          type="range"
          min={limits.min}
          max={limits.max}
          list="tickmarks"
          value={value}
          onChange={onChange}
          {...(onMouseUp && { onMouseUp: onMouseUp })}
          className={cn(
            'w-full',
            'h-2',
            'bg-silver-20',
            'ring-blue-80',
            'flex',
            'appearance-none',
            'items-center',
            'rounded-lg',
            { 'cursor-pointer': !disabled },
            'peer-focus:outline-none',
            'mt-[8px]'
          )}
          disabled={disabled}
        ></input>
      </div>
      {labels && (
        <Typography variant="body-small" className="ml-1 whitespace-normal">
          {labels.max}
        </Typography>
      )}
    </div>
  );
}
