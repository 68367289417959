import axios from 'axios';
import { Voyage } from '../models/Voyage';
import useSWR from 'swr';
import { getVoyageUrl } from './useVoyageSummaries';

export interface useVoyageLegsProps {
  currentVoyageNumber?: number | undefined;
  assetNumber?: number | undefined;
  accessToken: string | undefined;
}

const voyageLegsFetcher = (url: string, token: string) =>
  axios
    .get<{ data: Voyage }>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((res) => res.data.data);

export function useVoyageLegs({
  currentVoyageNumber,
  assetNumber,
  accessToken
}: useVoyageLegsProps) {
  const {
    data: voyageLegs,
    error: voyageError,
    isLoading: isLoadingVoyageLegs
  } = useSWR(
    () => {
      if (assetNumber && currentVoyageNumber) {
        return `${getVoyageUrl(assetNumber)}/${currentVoyageNumber}`;
      }
      return null;
    },
    (key) => {
      if (assetNumber && currentVoyageNumber && accessToken) {
        return voyageLegsFetcher(key, accessToken);
      }
      return null;
    }
  );
  return {
    data: voyageLegs,
    error: voyageError,
    isLoading: isLoadingVoyageLegs
  };
}
