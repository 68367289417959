import { VoyageLeg, VoyageSummary } from '../models/Voyage';

export enum TimeLabel {
  ActualArrival = 'Actual Arrival',
  ActualDeparture = 'Actual Departure',
  EstimatedArrival = 'Estimated Arrival',
  EstimatedDeparture = 'Estimated Departure',
  Arrival = 'Arrival time',
  Departure = 'Departure time'
}

export interface TimeInfo {
  key: TimeLabel;
  value: string | number | undefined;
}

export interface FormatedVoyageTime {
  arrivalTime: TimeInfo;
  departureTime: TimeInfo;
}

export function searchVoyageTime(
  voyage: VoyageLeg | VoyageSummary | undefined
): FormatedVoyageTime {
  // First we try to get if it is actual or estimated time for both berth and port times, prioritizing actual over estimated
  const berthArrival = {
    key: voyage?.ActualBerthArrivalLocal
      ? TimeLabel.ActualArrival
      : TimeLabel.EstimatedArrival,
    value: voyage?.ActualBerthArrivalLocal || voyage?.EstimatedBerthArrivalLocal
  };

  const berthDeparture = {
    key: voyage?.ActualBerthDepartureLocal
      ? TimeLabel.ActualDeparture
      : TimeLabel.EstimatedDeparture,
    value:
      voyage?.ActualBerthDepartureLocal || voyage?.EstimatedBerthDepartureLocal
  };

  const portArrival = {
    key: voyage?.ActualPortArrivalLocal
      ? TimeLabel.ActualArrival
      : TimeLabel.EstimatedArrival,
    value: voyage?.ActualPortArrivalLocal || voyage?.EstimatedPortArrivalLocal
  };

  const portDeparture = {
    key: voyage?.ActualPortDepartureLocal
      ? TimeLabel.ActualDeparture
      : TimeLabel.EstimatedDeparture,
    value:
      voyage?.ActualPortDepartureLocal || voyage?.EstimatedPortDepartureLocal
  };

  // Once we have or arrival or estimated, for both port and berth, we need to prioritize Berth over Port times
  // If there are no port or berth times, display DepartureLocal and ArrivalLocal times
  const arrivalTime = berthArrival.value
    ? berthArrival
    : portArrival.value
    ? portArrival
    : { key: TimeLabel.Arrival, value: voyage?.ArrivalLocal };
  const departureTime = berthDeparture.value
    ? berthDeparture
    : portDeparture.value
    ? portDeparture
    : { key: TimeLabel.Departure, value: voyage?.DepartureLocal };

  return { arrivalTime, departureTime };
}
