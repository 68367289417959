import cn from 'classnames';
import { Typography } from '@org-crowley/enterprise-react-component-library';
import { Vessel } from '../../models/Vessel';

export interface Props {
  vessels: Vessel[];
  filteredVessels: Vessel[];
  className?: string;
}

export const VesselCount = ({
  vessels,
  filteredVessels,
  className = ''
}: Props) => {
  return (
    <Typography
      variant="h100"
      className={cn('text-silver-90', className)}
    >{`${filteredVessels.length} out of ${vessels.length} Vessels`}</Typography>
  );
};
