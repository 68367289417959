import {
  VoyageSummary,
  VoyageSchedule,
  VoyageLegContainer
} from '../models/Voyage';

export enum VoyageActionType {
  CHANGE_TAB = 'CHANGE_TAB',
  SET_TAB_NAME = 'SET_TAB_NAME',
  SET_VOYAGES_AND_LEGS = 'SET_VOYAGES_AND_LEGS',
  SET_VOYAGE = 'SET_VOYAGE',
  SET_VOYAGE_LEG = 'SET_VOYAGE_LEG',
  SET_CURRENT_VOYAGE_LEGS = 'SET_CURRENT_VOYAGE_LEGS',
  BACK_BUTTON = 'BACK_BUTTON',
  SET_FILTERED_VOYAGES = 'SET_FILTERED_VOYAGES'
}
export interface VoyageAction {
  type: VoyageActionType;
  payload: VoyageState;
}
export interface VoyageState {
  allVoyages?: VoyageSchedule | undefined | null; //We recieve the complete voyage schedule were are all voyages, we filter the desired voyages from here
  currentVoyageLegContainers?: VoyageLegContainer[] | undefined; //This will hold the existing voyageLegs for the selected voyage.
  filteredVoyages?: VoyageSummary[] | undefined;
  activeIndex?: number; //This is the active index for the TabContent component
  selectedVoyageLegContainer?: VoyageLegContainer | undefined; // The selected voyageLeg within the currentVoyageLegs
  selectedVoyage?: VoyageSummary | undefined; // The selected voyageSummary from the current allVoyages
  tabName?: string; //This is for changing the name on the welcome screen depending on the selected tab
}

export const voyageReducer = (
  state: VoyageState,
  { type, payload }: VoyageAction
): VoyageState => {
  switch (type) {
    case 'CHANGE_TAB':
      return {
        ...state,
        tabName: payload.tabName,
        activeIndex: payload.activeIndex,
        selectedVoyage: undefined,
        selectedVoyageLegContainer: undefined
      };
    case 'SET_TAB_NAME':
      return { ...state, tabName: payload.tabName };
    case 'SET_VOYAGES_AND_LEGS': {
      return {
        ...state,
        allVoyages: payload.allVoyages,
        currentVoyageLegContainers: payload.currentVoyageLegContainers,
        selectedVoyageLegContainer: payload.selectedVoyageLegContainer
      };
    }
    case 'SET_VOYAGE':
      return { ...state, selectedVoyage: payload.selectedVoyage };
    case 'SET_VOYAGE_LEG':
      return {
        ...state,
        selectedVoyageLegContainer: payload.selectedVoyageLegContainer
      };
    case 'SET_CURRENT_VOYAGE_LEGS':
      return {
        ...state,
        currentVoyageLegContainers: payload.currentVoyageLegContainers
      };
    case 'BACK_BUTTON':
      return {
        ...state,
        selectedVoyage: undefined,
        selectedVoyageLegContainer: undefined
      };
    case 'SET_FILTERED_VOYAGES':
      return {
        ...state,
        filteredVoyages: payload.filteredVoyages
      };
    default:
      return state;
  }
};
