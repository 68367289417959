export interface VoyageSchedule {
  voyageSummaries: VoyageSummary[];
  /*
   * Index of current voyage summary used for
   * retrieving current voyage legs
   **/
  currentVoyage: number;
}

export interface VoyageSummary {
  ActualBerthArrivalLocal?: number;
  ActualBerthDepartureLocal?: string;
  ActualPortArrivalLocal?: number;
  ActualPortDepartureLocal?: string;
  ArrivalLocal?: number;
  ArrivalLocalRaw?: string;
  AssetNumber: number;
  Charterer: string;
  DepartureLocal?: number;
  DepartureLocalRaw?: string;
  EstimatedBerthArrivalLocal?: number;
  EstimatedBerthDepartureLocal?: number;
  EstimatedPortArrivalLocal?: number;
  EstimatedPortDepartureLocal?: number;
  IMONumber: number;
  OprType: string;
  VoyageNumber: number; // unique id for voyage
  VoyageStatus: VoyageStatus;
  /**
   * 1 - Commenced
   * 2 - Scheduled
   * 3 - Forecasted
   * 4 - Completed
   * 5 - Closed
   * 6 - Canceled
   */
  VoyageStatusOrder: number;
}

export interface VoyageLegContainer {
  Port: string;
  voyageLegs: VoyageLeg[];
}

export interface Voyage {
  voyageLegContainers: VoyageLegContainer[];
  /*
   * Unique Id for voyage
   **/
  VoyageNumber: number | undefined;
  VoyageStatus: VoyageStatus | undefined;
}

/*
 * Must be kept in sync with VoyageLeg in back-end API for /v2/vessels/:assetNumber/voyages/:voyageNumber
 **/
export interface VoyageLeg {
  ActualBerthArrivalLocal?: number;
  ActualBerthDepartureLocal?: string;
  ActualPortArrivalLocal?: number; // we will have either this, or ActualBerthArrivalLocal
  ActualPortDepartureLocal?: string; // we will have either this, or ActualBerthDepartureLocal
  Activity?: Activity;
  ArrivalDepartureStatus: ArrivalDepartureStatus;
  ArrivalLocal?: number; // veson return used with Berth, Port and ArrivalDepartureStatus to populate [actual/estimate berth/port arrival] attributes
  ArrivalLocalRaw?: string; // veson return used Berth, Port and ArrivalDepartureStatus to populate [actual/estimate berth/port arrival] attributes
  AssetNumber: number;
  Berth?: string;
  Charterer: string;
  Country?: string; //TODO: Not sure if this will be available
  DepartureLocal?: number; // veson return used Berth, Port and ArrivalDepartureStatus to populate [actual/estimate berth/port departure] attributes
  DepartureLocalRaw?: string; // veson return used Berth, Port and ArrivalDepartureStatus to populate [actual/estimate berth/port departure] attributes
  EstimatedBerthArrivalLocal?: number;
  EstimatedBerthDepartureLocal?: number;
  EstimatedPortArrivalLocal?: number;
  EstimatedPortDepartureLocal?: number;
  IMONumber: number;
  LastVoyageUpdateGMT: number; // Veson provided update
  LastVoyageUpdateGMTRaw: string; // Veson provided update
  OfficialNumber: number;
  OprType: OprType; // maybe an enum, will get confirmation from Manjusha
  Order: number; // 000->100->200-> etc.
  PK: string; // partition key
  Port: string; // non-nullable
  RemarksOperations?: string;
  SK: string; // sort key
  UpdatedAt?: number; // current timestamp when updated in shipping dynamoDB (Crowley team's normalization and aggregation software generated)
  UpdatedBy?: string; // i.e. veson-voyage-schedule
  VesselCode: number; // unique id for Veson(etc.)
  VoyageNumber: number; // unique id for voyage
  VoyageStatus: VoyageStatus;
  /**
   * 1 - Commenced
   * 2 - Scheduled
   * 3 - Forecasted
   * 4 - Completed
   * 5 - Closed
   * 6 - Canceled
   */
  VoyageStatusOrder: number;
  cargoItems?: CargoItem[];
}

export enum FunctionCode {
  L = 'L',
  D = 'D'
}

export interface CargoItem {
  PK: string;
  SK: string; // sort key
  AssetNumber: number;
  BerthSeqNumber: number;
  BLCode?: string;
  BLDate?: string;
  BLProductNet: number;
  BLQuantity: number;
  BLUnit?: string;
  CargoItinerarySeqNumber: number;
  CargoShortName?: string;
  FunctionCode: FunctionCode; //L - LOAD, D - DISCHARGE
  IMONumber: number;
  LastCargoUpdateGMT: number;
  LastCargoUpdateGMTRaw: string;
  NominatedLiftQuantity: number;
  NominatedLiftUnit?: string;
  Port: string;
  PortNumber: number; // Veson port code
  UpdatedAt: number;
  UpdatedBy: string;
  VesselCode: number; // unique id for Veson
  VesselName: string;
  VoyageItineraryOrderNumber: number;
  VoyageNumber: number;
  VoyageSeqNumber: number;
}

export enum ArrivalDepartureStatus {
  SA = 'SA', // 3443-TODO: Arrival & Departure are actual
  TE = 'TE', // 3443-TODO: Arrival & Departure are actual
  AR = 'AR', // 3443-TODO: Arrival is actual & Departure is estimated
  Estimated = '..' // 3443-TODO: Arrival & Departure are estimated
}

export enum OprType {
  OVOV = 'OVOV',
  OVTO = 'OVTO',
  RELT = 'RELT',
  TCOV = 'TCOV',
  TCTO = 'TCTO'
}

/** Activity can also be null on a voyage leg */
export enum Activity {
  Discharge = 'Discharge',
  Fueling = 'Fueling',
  Load = 'Load',
  Repair = 'Repair',
  SeaBuoy = 'Sea Buoy',
  Shipyard = 'Shipyard',
  Waiting = 'Waiting'
}

export enum VoyageStatus {
  Forecast = 'Forecast',
  Scheduled = 'Scheduled',
  Commenced = 'Commenced',
  Completed = 'Completed',
  Canceled = 'Canceled',
  Closed = 'Closed'
}
