import axios from 'axios';
import useSWR from 'swr';
import { VoyageSchedule } from '../models/Voyage';

export interface useVoyageSummariesProps {
  shouldFetch: boolean;
  assetNumber?: number | undefined;
  accessToken: string | undefined;
}

const scheduleFetcher = (url: string, token: string) =>
  axios
    .get<{ data: VoyageSchedule }>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((res) => res.data.data);

export const getVoyageUrl = (assetNumber: number) => {
  return `${process.env.NEXT_PUBLIC_API_BASE}/v2/vessels/${assetNumber}/voyages`;
};

export function useVoyageSummaries({
  shouldFetch = true,
  assetNumber,
  accessToken
}: useVoyageSummariesProps) {
  const {
    data: voyageSchedule,
    error: voyageScheduleError,
    isLoading: isLoadingVoyageSchedule
  } = useSWR(
    () => {
      if (shouldFetch && assetNumber) {
        return getVoyageUrl(assetNumber);
      }
      return null;
    },
    (key) => {
      if (shouldFetch && assetNumber && accessToken) {
        return scheduleFetcher(key, accessToken);
      }
      return null;
    }
  );

  return {
    data: voyageSchedule,
    error: voyageScheduleError,
    isLoading: isLoadingVoyageSchedule
  };
}
