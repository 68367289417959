import cn from 'classnames';
import { Icon } from '@org-crowley/enterprise-react-component-library';
import { MdCheckCircle } from 'react-icons/md';
import { ArrivalDepartureStatus } from '../../models/Voyage';

export interface VoyageLegIconProps {
  status: ArrivalDepartureStatus;
  /**
   * Icon height and width in pixels
   */
  size: number;
  className?: string;
}

const VoyageLegIcon = ({
  status,
  size,
  className = ''
}: VoyageLegIconProps) => {
  const IconMap = {
    [ArrivalDepartureStatus.SA]: (
      <MdCheckCircle size={size} className={cn('text-green-50', className)} />
    ),
    [ArrivalDepartureStatus.TE]: (
      <MdCheckCircle size={size} className={cn('text-green-50', className)} />
    ),
    [ArrivalDepartureStatus.AR]: (
      <Icon
        iconName="VoyageUnderway"
        className={cn('rounded-full text-blue-80', className)}
        iconStyles={{ width: `${size}px`, height: `${size}px` }}
      />
    ),
    [ArrivalDepartureStatus.Estimated]: (
      <div
        className={cn('rounded-full border-2 border-silver-50', className)}
        style={{ width: `${size}px`, height: `${size}px` }}
      />
    )
  };

  return <>{IconMap[status]}</>;
};

export default VoyageLegIcon;
